import React, { lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import defaultPath from "utiles/path";

import { Index } from "pages/Index";
import NotFound from "pages/error/NotFound";
import BodyFixed from "components/common/BodyFixed";
import { Loading } from "components/shared/Loading";

import {
  RedirectToIndexRemoveQuery,
  RedirectToRemoveSlash,
  RedirectToQueryConversion,
  RedirectToSiteQuery,
  RedirectLegacyToIndex,
  RedirectToCampaign,
} from "routes/Redirect";

interface Rooter {
  path: string;
  Components: ({ pathname, search, state }) => React.ReactElement;
}

export const routes = {
  root: "/",
  challengeDetail: `${defaultPath.INDEX}/challenge/detail`,
  historyDetail: `${defaultPath.INDEX}/history/detail`,
  waitingDetail: `${defaultPath.INDEX}/waiting/detail`,
};

// 動的にページコンポネートを読み込む
const Detail = lazy(() => import("pages/detail"));
const Campaign = lazy(() => import("pages/campaign"));
const Category = lazy(() => import("pages/category"));
const Survey = lazy(() => import("pages/survey"));
const History = lazy(() => import("pages/menus/history"));
const Challenge = lazy(() => import("pages/menus/challenge/Challenge"));
const PreRegist = lazy(() => import("pages/menus/preregist"));
const Waiting = lazy(() => import("pages/menus/waiting"));
const Contact = lazy(() => import("pages/menus/contact"));
const SentContact = lazy(() => import("pages/menus/contact/SentContact"));
const Tutorial = lazy(() => import("pages/menus/tutorial"));
const Debug = lazy(() => import("pages/debug"));
const Free = lazy(() => import("pages/free"));
const PrivacyPolicy = lazy(() => import("pages/privacyPolicy"));
const QuizPage = lazy(() => import("pages/quiz"));
const Caution = lazy(() => import("pages/caution"));
const ChallengeDetail = lazy(() => import("pages/detail/challenge"));
const HistoryDetail = lazy(() => import("pages/detail/history"));
const WaitingDetail = lazy(() => import("pages/detail/waiting"));
const Registered = lazy(() => import("components/detail/Registered"));
const Verified = lazy(() => import("components/detail/Verified"));

// ここで読み込む画面コンポーネント内では、毎回必ずtitle属性を更新すること
// 例）document.title = mediaData.wall_title;
const router: Rooter[] = [
  {
    path: defaultPath.LEGACYINDEX,
    Components: (props) => <RedirectLegacyToIndex {...props} />,
  },
  {
    path: defaultPath.INDEX,
    Components: (props) => (
      <RedirectToIndexRemoveQuery {...props}>
        <Index />
      </RedirectToIndexRemoveQuery>
    ),
  },
  {
    path: `${defaultPath.INDEX}/pickup`,
    Components: () => <Category />,
  },
  {
    path: `${defaultPath.INDEX}/survey`,
    Components: () => <Survey />,
  },
  {
    path: `${defaultPath.INDEX}/detail`,
    Components: (props) => (
      <RedirectToQueryConversion {...props}>
        <Detail />
      </RedirectToQueryConversion>
    ),
  },
  {
    path: `${defaultPath.INDEX}/campaign`,
    Components: () => <Campaign />,
  },
  {
    path: `${defaultPath.INDEX}/history`,
    Components: () => <History />,
  },
  {
    path: routes.historyDetail,
    Components: () => <HistoryDetail />,
  },
  {
    path: `${defaultPath.INDEX}/challenge`,
    Components: () => <Challenge />,
  },
  {
    path: routes.challengeDetail,
    Components: () => <ChallengeDetail />,
  },
  {
    path: `${defaultPath.INDEX}/pre-regist`,
    Components: () => <PreRegist />,
  },
  {
    path: `${defaultPath.INDEX}/waiting`,
    Components: () => <Waiting />,
  },
  {
    path: routes.waitingDetail,
    Components: () => <WaitingDetail />,
  },
  {
    path: `${defaultPath.INDEX}/tutorial`,
    Components: () => <Tutorial />,
  },
  {
    path: `${defaultPath.INDEX}/registered`,
    Components: () => <Registered />,
  },
  {
    path: `${defaultPath.INDEX}/mail-verified`,
    Components: () => <Verified />,
  },
  {
    path: `${defaultPath.INDEX}/contact`,
    Components: () => <Contact />,
  },
  {
    path: `${defaultPath.INDEX}/contact/sent`,
    Components: () => <SentContact />,
  },
  {
    path: `${defaultPath.INDEX}/free`,
    Components: () => <Free />,
  },
  {
    path: `${defaultPath.OW}/privacyPolicy`,
    Components: () => <PrivacyPolicy />,
  },
  {
    path: `${defaultPath.OW}/caution`,
    Components: () => <Caution />,
  },
  {
    path: `${defaultPath.OW}/quizPage`,
    Components: () => <QuizPage />,
  },
];

export const Routing = () => {
  const query = new URLSearchParams(location.search);
  const owpQuery = query.get("_owp");
  const isAmplify = /^(?=.*amplifyapp).*$/.test(window.location.href);

  if (window.location.pathname !== `${defaultPath.OW}/quizpage`) {
    if (
      ((!owpQuery || !query) && !isAmplify) ||
      ((!owpQuery || !query) && isAmplify && window.location.pathname !== "/")
    ) {
      return (
        <Switch>
          <Route path="*" component={NotFound} />
        </Switch>
      );
    }
  }

  return (
    <BodyFixed>
      <RedirectToSiteQuery>
        <React.Suspense fallback={<Loading />}>
          <Switch>
            {router.map(({ path, Components }, index) => (
              <Route
                key={index}
                exact
                path={path}
                render={(props) => (
                  <RedirectToCampaign search={location.search}>
                    <RedirectToRemoveSlash
                      pathname={props.location.pathname}
                      search={props.location.search}
                      state={props.location.state}
                    >
                      <Components
                        pathname={props.location.pathname}
                        search={props.location.search}
                        state={props.location.state}
                      />
                    </RedirectToRemoveSlash>
                  </RedirectToCampaign>
                )}
              />
            ))}
            <Route
              path={`${defaultPath.OW}/contact`}
              render={(props) => (
                <Redirect
                  to={{
                    pathname: `${defaultPath.INDEX}/contact`,
                    search: props.location.search,
                    state: props.location.state,
                  }}
                  push
                />
              )}
            />
            {isAmplify && <Route path="/" component={Debug} />}
            <Route path="*" component={NotFound} />
          </Switch>
        </React.Suspense>
      </RedirectToSiteQuery>
    </BodyFixed>
  );
};
