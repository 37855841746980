import React from "react";
import NotFound from "pages/error/NotFound";
import { ERROR_PATTERN } from "./ErrorBoundary";
import OtherErrors from "pages/error/OtherErrors";
import { Loading } from "components/shared/Loading";

interface ErrorBoundaryFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
  onError?: (error: Error, componentStack: string) => void;
}

export const ErrorBoundaryFallback: React.FC<ErrorBoundaryFallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  const isIconUrlFailedToLoad = error.toString().includes("iconUrl");
  const isErrorContainsChunkLoadError = error.toString().includes("ChunkLoadError");
  const isErrInternetDisconnected = error.toString().includes("ERR_INTERNET_DISCONNECTED");
  const isApiError = error.toString() === ERROR_PATTERN.API;
  const isNotFound =
    isApiError ||
    isErrorContainsChunkLoadError ||
    isErrInternetDisconnected ||
    isIconUrlFailedToLoad;
  const isDetail = window.location.pathname === "/ad/p/ow/bear/detail";

  return isDetail ? (
    // 直接OW詳細画面を開く場合があるので、詳細画面の場合はエラーページを表示しない
    <Loading />
  ) : isNotFound ? (
    <NotFound resetErrorBoundary={resetErrorBoundary} />
  ) : (
    <OtherErrors resetErrorBoundary={resetErrorBoundary} />
  );
};
