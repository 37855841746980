import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectRouter } from "store/selector";
import Styles from "stylesheets/shared/Footer.module.scss";
import CommonStyles from "stylesheets/CommonStyles.module.scss";
import FontStyles from "stylesheets/FontStyles.module.scss";
import { Menus } from "models";
import clsx from "clsx";
import defaultPath from "utiles/path";
import { offerType, offerwallType } from "types/offerwallType";
import { MENU_TYPE } from "types/MenuType";
import { filterMenus } from "modules/filterMenus";
import { TopSurvey } from "models/topSurvey";

interface Color {
  bgColor: string;
  txtColor: string;
}
interface Props {
  text: string;
  color: Color;
  menus?: Menus[];
  offerwallType?: offerwallType;
  footerType?: "primary" | "secondary";
  surveys?: TopSurvey[];
  privacyPolicyUrl?: string;
  cookiePolicyUrl?: string;
  customerSupportEmail?: string;
}

export const Footer: React.FC<Props> = ({
  text,
  color,
  menus,
  offerwallType,
  footerType = "primary",
  surveys,
  privacyPolicyUrl,
  cookiePolicyUrl,
  customerSupportEmail,
}) => {
  const { location } = useSelector(selectRouter);

  // メニューの中にお問い合わせが有ればtrueを返す。falseの場合はお問い合わせへの動線を隠す
  function isCheckMenuType(menus: Menus[]): boolean {
    const filteredMenus = filterMenus(menus);
    return filteredMenus.some((menu) => menu.menuType === MENU_TYPE.CONTACT);
  }

  const selectedCustomerSupportEmail = customerSupportEmail || "sf_cs@skyfall.co.jp";

  return footerType === "primary" ? (
    <footer
      data-testid="footer"
      className={CommonStyles.center}
      style={{ backgroundColor: color.bgColor }}
    >
      <div>
        <div
          className={clsx(Styles.footer, FontStyles.small)}
          style={{
            color:
              offerwallType === offerType.TRIP_MILE
                ? "#7F6003"
                : (offerwallType === offerType.LINE_MANGA || offerwallType === offerType.MANGA) &&
                  color.txtColor,
            padding: `8px 16px ${text ? 52 : 73}px 16px`,
          }}
        >
          {offerwallType === offerType.LINE_MANGA && (
            <div>
              本サービスは必ず
              <a
                className={Styles.link}
                style={{
                  color: color.txtColor,
                }}
                href="https://terms2.line.me/linemanga_reward_ads_agree/sp?lang=ja"
                rel="noreferrer"
              >
                利用規約
              </a>
              に同意の上ご利用ください。
              <br />
            </div>
          )}
          本サービスのご利用は、各キャンペーンの獲得条件・注意事項をご確認いただき、同意の上ご参加ください。
          <br />
          本サービスの個人情報の取扱いについては、こちらの
          {privacyPolicyUrl ? (
            <a
              className={Styles.link}
              style={{
                color:
                  offerwallType === offerType.TRIP_MILE
                    ? "#7F6003"
                    : (offerwallType === offerType.LINE_MANGA ||
                        offerwallType === offerType.MANGA) &&
                      color.txtColor,
              }}
              target="_blank"
              href={privacyPolicyUrl}
              rel="noreferrer"
              data-testid="privacyPolicy"
            >
              プライバシーポリシー
            </a>
          ) : (
            <Link
              className={Styles.link}
              data-testid="privacyPolicy"
              style={{
                color:
                  offerwallType === offerType.TRIP_MILE
                    ? "#7F6003"
                    : (offerwallType === offerType.LINE_MANGA ||
                        offerwallType === offerType.MANGA) &&
                      color.txtColor,
              }}
              to={{
                pathname: `${defaultPath.OW}/privacyPolicy`,
                search: location.search,
              }}
            >
              プライバシーポリシー
            </Link>
          )}
          をご確認下さい。
          <div>
            Cookieの取扱いに関するポリシーはこちらの
            <a
              className={Styles.link}
              style={{
                color:
                  offerwallType === offerType.TRIP_MILE
                    ? "#7F6003"
                    : (offerwallType === offerType.LINE_MANGA ||
                        offerwallType === offerType.MANGA) &&
                      color.txtColor,
              }}
              target="_blank"
              href={cookiePolicyUrl}
              rel="noreferrer"
              data-testid="cookiePolicy"
            >
              クッキーポリシー
            </a>
            をご確認ください。
            <br />
          </div>
          {offerwallType !== offerType.PICCOMA_APP &&
            offerwallType !== offerType.PICCOMA_WEB &&
            surveys?.map((survey: TopSurvey, i) => (
              <React.Fragment key={i}>
                {survey.changeProfileUrl && (
                  <>
                    {survey.displayName}
                    が管理するアンケートをご利用の方で、登録情報が変更になりましたら
                    <a
                      className={Styles.link}
                      style={{
                        color:
                          offerwallType === offerType.TRIP_MILE
                            ? "#7F6003"
                            : (offerwallType === offerType.LINE_MANGA ||
                                offerwallType === offerType.MANGA) &&
                              color.txtColor,
                      }}
                      target="_blank"
                      href={survey.changeProfileUrl}
                      rel="noreferrer"
                      data-testid="surveyRegist"
                    >
                      こちら
                    </a>
                    から変更をお願い致します。
                    <br />
                  </>
                )}
                {survey.contactUrl && (
                  <>
                    {survey.displayName}が管理するアンケートに関するお問合せは
                    <a
                      className={Styles.link}
                      style={{
                        color:
                          offerwallType === offerType.TRIP_MILE
                            ? "#7F6003"
                            : (offerwallType === offerType.LINE_MANGA ||
                                offerwallType === offerType.MANGA) &&
                              color.txtColor,
                      }}
                      target="_blank"
                      href={survey.contactUrl}
                      rel="noreferrer"
                      data-testid="surveyAbout"
                    >
                      こちら
                    </a>
                    からお願い致します。
                    <br />
                  </>
                )}
              </React.Fragment>
            ))}
          {isCheckMenuType(menus) && (
            <>
              本サービスに関するお問い合わせは
              <Link
                className={Styles.link}
                style={{
                  color:
                    offerwallType === offerType.TRIP_MILE
                      ? "#7F6003"
                      : (offerwallType === offerType.LINE_MANGA ||
                          offerwallType === offerType.MANGA) &&
                        color.txtColor,
                }}
                to={{
                  pathname: `${defaultPath.OW}/contact`,
                  search: location.search,
                  state: {
                    display: menus.find((data) => data.menuType === MENU_TYPE.CONTACT)?.title,
                    referrer: location,
                  },
                }}
                data-testid="otherInquiry"
              >
                こちら
              </Link>
              からお願い致します。
              <br />
              ドメイン指定をされている場合は、返信メールが受信できるように指定のメールドメイン「
              {selectedCustomerSupportEmail}」をあらかじめ受信指定に追加設定をお願いします。
            </>
          )}
        </div>
        <div
          style={{ backgroundColor: color.bgColor, color: color.txtColor }}
          className={clsx(Styles["footer-text"], CommonStyles.center)}
        >
          {text && <p>{text}</p>}
        </div>
      </div>
    </footer>
  ) : (
    footerType === "secondary" && (
      <footer
        data-testid="footer"
        className={CommonStyles.center}
        style={{ backgroundColor: color.bgColor }}
      >
        <div>
          <div
            style={{ backgroundColor: color.bgColor, color: color.txtColor }}
            data-testid="footer"
            className={clsx(Styles["footer-text"], CommonStyles.center)}
          >
            {text && <p>{text}</p>}
          </div>
        </div>
      </footer>
    )
  );
};
