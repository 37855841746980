import React, { useCallback, useLayoutEffect, useEffect, useMemo, useRef, useState } from "react";
import { Header } from "components/shared/Header";
import { Loading } from "components/shared/Loading";
import MainContents from "../components/index/MainContents";
import { Menu } from "../components/index/Menu";
import PikkomaOffersList from "../components/index/OffersList";
import { OffersList } from "SkyflagFrontendOfferwall/components/index/OffersList";
import { useScrollToDataPosition } from "hooks/useScrollToDataPosition";
import { selectMenuState, selectPopupState, selectDisplayIndexState } from "store/selector";
import { useSelector, useDispatch } from "react-redux";
import MenuModules from "slices/menu";
import { wait } from "utiles/wait";
import { Offer, topChallenging } from "models";
import { useOffers } from "hooks/useOffers";
// import { Index as ArmadilloIndex } from "SkyflagFrontendOfferwall/pages/Index";
import { Head } from "components/common/head";
import { offerInit } from "modules/offer";
import { offerType } from "types/offerwallType";
import Banner from "SkyflagFrontendOfferwall/components/index/Banner";
import { CurrentOffers } from "interfaces/offers";
import { popupType } from "types/popupType";
import { RecommendOffer } from "components/index/RecommendOffer";
import { RecommendCampaign } from "components/index/RecommendCampaign";
import { TutorialPopup } from "components/index/TutorialPopup";
import PopupModules from "slices/popup";
import ServiceTypeModules from "slices/serviceType";
import { serviceType } from "types/serviceType";
import { useHistory } from "react-router-dom";
import { LocationState } from "types/locationType";
import { superSaleType } from "types/superSaleBannerType";
import NoOfferContent from "components/index/NoOfferContent";
import { surveyDisplayType } from "types/surveyDisplayType";
import defaultPath from "utiles/path";
import { Banners } from "models/banners";
import { useErrorHandler } from "react-error-boundary";
import { ERROR_PATTERN } from "components/common/ErrorBoundary";
import { MenuIcon } from "components/index/MenuIcon";
import stylesResponsive from "stylesheets/index/MainContents.module.scss";
import stylesPortrait from "stylesheets/index/MainContentsPortrait.module.scss";
import { useLandscape } from "hooks/useLandscape";
import { DisplayTabType } from "utiles/offer";

export const Index: React.FC = () => {
  const dispatch = useDispatch();
  const { isMenu } = useSelector(selectMenuState);
  const { isPopup } = useSelector(selectPopupState);
  const { displayIndex } = useSelector(selectDisplayIndexState);
  const ref = useRef<HTMLDivElement>(null);
  const history = useHistory<LocationState>();
  const isLandscape = useLandscape();
  const queries = new URLSearchParams(window.location.search);
  const closeLinkParam = queries.get("close_link") && decodeURIComponent(queries.get("close_link"));
  const [offers, setOffers] = useState<CurrentOffers[]>([]);
  const [carouselBanners, setCarouselBanners] = useState<Banners[]>([]);
  const [isFixedMenu, setIsFixedMenu] = useState(false);

  const position = useMemo(() => history.location.state?.referrer?.query?.position, [history]);
  const viewType = useMemo(() => history.location.state?.referrer?.query?.view_type, [history]);
  const [
    errOffers,
    isLoadedOffers,
    offerList,
    allOffers,
    fetchOffers,
    mediaData,
    tabs,
    challengings,
    popupData,
    surveys,
    abTestSurveys,
    PickupOffers,
    isTabsScroll,
    ,
    tutorialHeaderImage,
    tutorialBanners,
  ] = useOffers();

  const handleError = useErrorHandler();
  if (errOffers) {
    handleError(ERROR_PATTERN.API);
  }
  // TODO今だけ実装のやっつけ条件分岐、修正予定
  const isPiccoma =
    mediaData.offerwallType === offerType.PICCOMA_APP ||
    mediaData.offerwallType === offerType.PICCOMA_WEB;

  const isOtherElementsAdd =
    !mediaData.isBannerTabHidden &&
    (isPiccoma || mediaData.offerwallType === offerType.TRIP_MILE) &&
    mediaData.superSaleBannerType === superSaleType.BANNER;

  const Styles =
    mediaData.offerwallType === offerType.TRIP_MILE ? stylesPortrait : stylesResponsive;

  // トリマFMTの際は縦固定にする
  const isLandscapeDisplay = mediaData.offerwallType === offerType.TRIP_MILE ? false : isLandscape;

  const isIncludeTutorialOffer = useMemo(
    () => allOffers.some((offer) => offer.isTutorialOffer),
    [allOffers]
  );

  const [popupIndex, setPopupIndex] = useState<number>(0);
  const nextpopup = () => {
    setPopupIndex(popupIndex + 1);
    if (popupIndex === popupData.length - 1) dispatch(PopupModules.actions.setIsPopup(true));
  };
  // TODO スクロール系の改善
  const scrollToViewType = (selector: string): void => {
    const element = document.querySelector(selector);
    if (element && mediaData.banners && mediaData.banners.length > 0 && !isPiccoma) {
      const adjustedTopPosition = element.getBoundingClientRect().top - 110; // 110は一番上にバナーを追加するとなぜか必要になる
      window.scrollBy({ top: adjustedTopPosition, behavior: "auto" });
    } else if (element) {
      element.scrollIntoView({ behavior: "auto" });
    }
  };

  useLayoutEffect(() => {
    if (mediaData?.redirectPageUrl) {
      queries.delete("_owp");
      window.location.href = `${mediaData.redirectPageUrl}&${queries.toString()}`;
    }
  }, [mediaData.redirectPageUrl]);
  // scrollまでの時間差をつけなければいけない時に使用
  const scrollDelay = 70;

  useEffect(() => {
    if (viewType) {
      const selector = `[data-view-type="${viewType}"]`;
      if (isOtherElementsAdd) {
        // バナー等の要素が追加された場合
        setTimeout(() => {
          scrollToViewType(selector);
        }, scrollDelay);
      } else {
        scrollToViewType(selector);
      }
    }
  }, [mediaData.banners, viewType]);

  const isApiLoaded = isLoadedOffers;
  const { getAddOffers, scrollTo, isLoaded } = useScrollToDataPosition(
    Number(position || 0),
    isLoadedOffers,
    fetchOffers,
    ref,
    undefined,
    mediaData?.banners?.length > 0
  );

  const [intersecting, setIntersecting] = useState(false);
  const paginateRef = useRef<HTMLDivElement>(null) as React.MutableRefObject<HTMLDivElement>;

  const addOffersOnScreen = useCallback((offers: Offer[], index: number) => {
    setOffers((prev) => [
      ...prev.map((data, i) =>
        i === index
          ? { tabDisplayType: data.tabDisplayType, offers: [...prev[index].offers, ...offers] }
          : data
      ),
    ]);
  }, []);

  // ページネーションのリクエスト定義
  const paginate = useCallback(async () => {
    if (mediaData.isBannerTabHidden === false) {
      const offerLength = offers[displayIndex].offers?.length;
      const additionalOffers: Offer[] = await fetchOffers(offerLength);
      addOffersOnScreen(additionalOffers, displayIndex);
    }
  }, [offers, displayIndex, mediaData, addOffersOnScreen, fetchOffers]);

  // Menuの表示・非表示切り替え
  const setIsMenu = useCallback(
    (values: boolean) => dispatch(MenuModules.actions.setIsMenu(values)),
    [dispatch]
  );

  const switchMenu = useCallback((): void => {
    setIsMenu(!isMenu);
  }, [isMenu]);

  const filterChallengings = useCallback(
    (offers: topChallenging[]): topChallenging[] => offers.filter((_, i) => i < 10),
    [challengings]
  );

  const setPaginateRef = useCallback(
    (node: HTMLDivElement | null) => {
      paginateRef.current = node;
      if (paginateRef.current) {
        const observer = new IntersectionObserver(([entry]) => {
          setIntersecting(entry.isIntersecting);
        });
        observer.observe(paginateRef.current);
      }
    },
    [paginateRef]
  );

  useEffect(() => {
    if (intersecting && paginate) {
      paginate();
    }
  }, [intersecting, paginate]);

  // 表示する案件の初期化
  useLayoutEffect(() => {
    if (offers?.length === 0) {
      setOffers((prev) => [...prev, ...offerInit(offerList, 10)]);
    }
    dispatch(ServiceTypeModules.actions.setServiceType(serviceType.OW));
  }, [offerList, tabs]);

  useLayoutEffect(() => {
    document.body.style.overflow = isMenu ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isMenu]);

  useEffect(() => {
    if (mediaData.surveyDisplayType === surveyDisplayType.CAROUSELBANNER) {
      const mediaBanners = mediaData.banners;
      const surveyCarouselBanner = {
        imageUrl: mediaData.bannerImageUrl,
        linkUrl: `${defaultPath.INDEX}/survey`,
        bannerType: 1,
        order: mediaData.banners.length + 2,
      };
      mediaBanners.push(surveyCarouselBanner);
      setCarouselBanners(mediaBanners);
    } else {
      setCarouselBanners(mediaData.banners);
    }
  }, [mediaData.banners]);

  const setRef = useCallback(
    async (node: HTMLDivElement) => {
      ref.current = node;
      const slideSelector = ref?.current?.querySelector(`.is-slide-active`);

      if (isLoaded) {
        if (!ref.current || mediaData.isBannerTabHidden) return;
        await wait(addOffersOnScreen(getAddOffers, displayIndex));

        // 新マンガタイプでもslideSelectorを渡すように（useScrollToDataPositionを参照）
        if (isOtherElementsAdd) {
          // バナー等の要素が追加された場合
          setTimeout(() => {
            scrollTo({
              type:
                (mediaData.banners.length && !isPiccoma) ||
                mediaData.offerwallType === offerType.TRIP_MILE
                  ? "normal"
                  : "bear",
              slide: slideSelector,
              scrollValue: mediaData.banners.length && !isPiccoma ? 30 : 0,
              offerwallType: mediaData.offerwallType,
            });
          }, scrollDelay);
        } else {
          scrollTo({
            type:
              (mediaData.banners.length && !isPiccoma) ||
              mediaData.offerwallType === offerType.TRIP_MILE
                ? "normal"
                : "bear",
            slide: slideSelector,
            scrollValue: mediaData.banners.length && !isPiccoma ? 30 : 0,
            offerwallType: mediaData.offerwallType,
          });
        }
      }

      ref.current &&
        (window.onscroll = () => {
          const scrollY = ref.current?.scrollTop;
          const trigger = ref.current?.querySelectorAll(".data-menu")[0];
          const triggerClientRect = trigger?.getBoundingClientRect();
          const triggerY =
            scrollY +
            triggerClientRect?.top -
            (mediaData.offerwallType === offerType.TRIP_MILE ? 0 : 49);
          if (scrollY <= triggerY) {
            setIsFixedMenu(false);
          } else {
            setIsFixedMenu(true);
          }
        });
    },
    [isLoaded, mediaData]
  );
  const scrollYsRef = useRef({});
  // タブ変更時トップへ
  const onScrollTopToTabs = useCallback(({ active, next }: { active: number; next: number }) => {
    const windowScrollY = window?.scrollY;
    const scrollYs = scrollYsRef.current;
    const triggerClientRect = document?.querySelectorAll(`.data-menu`)[0]?.getBoundingClientRect();
    const triggerY = scrollYs[next] ? scrollYs[next] : windowScrollY + triggerClientRect?.top;

    scrollYsRef.current = {
      ...scrollYsRef.current,
      [String(active)]: windowScrollY >= triggerY ? windowScrollY : 0,
    };
    if (windowScrollY >= triggerY || scrollYsRef.current[next]) {
      setTimeout(() => window?.scrollTo(0, triggerY), 0);
    }
  }, []);

  const BannerElement = <Banner data={carouselBanners} key="banners" />;

  if (!isApiLoaded || (position > 0 && !isLoaded)) {
    return <Loading />;
  }

  return (
    <div className={Styles.container}>
      <Head />
      {mediaData.offerwallType !== offerType.TRIP_MILE && (
        <Header
          title={mediaData?.wallTitle}
          icon={mediaData?.wallIconUrl}
          closeIconHide={mediaData.isCloseIconHidden}
          color={{
            bgColor: mediaData?.colors?.titleBgColor,
            txtColor: mediaData?.colors?.titleTxtColor,
          }}
          closeLink={closeLinkParam ?? mediaData?.closeLink}
          isMenu={isMenu}
          iconType={mediaData?.isPiccomaWeb ? "secondary" : "primary"}
          isUnderbar={isMenu}
          switchMenu={switchMenu}
          isWindowClose={mediaData?.isPiccomaWeb}
          isTitleHidden={mediaData.isWallTitleHidden}
          isFixed
          onClose={() => {
            setIsMenu(false);
            window.open(mediaData?.closeLink, "_self").close();
          }}
        />
      )}
      {/* 表示データが存在する時のみポップアップ表示 */}
      {popupData.length > 0 &&
        !isPopup &&
        !isIncludeTutorialOffer &&
        popupData.map((data, i) => (
          <div key={i}>
            {data.type === popupType.TUTOLIAL ? (
              <TutorialPopup
                offerwallType={mediaData.offerwallType}
                isOpen={popupIndex === i}
                nextpopup={nextpopup}
                tutorialBanners={tutorialBanners}
              />
            ) : data.type === popupType.SINGULE_OFFER ? (
              <RecommendOffer
                linkUrl={data.offers[0].linkUrl}
                pointIconUrl={mediaData.pointIconUrl}
                color={{
                  appAreaBgColor: mediaData.colors.appAreaBgColor,
                  appAreaTxtColor: mediaData.colors.appAreaTxtColor,
                  cvConditionColor: mediaData.colors.cvConditionColor,
                  dlBtnBgColor: mediaData.colors.dlBtnBgColor,
                  dlBtnTxtColor: mediaData.colors.dlBtnTxtColor,
                }}
                isSale={data.offers[0].isSale}
                imageUrl={data.offers[0].imageUrl}
                name={data.offers[0].name}
                AchievementPointName={data.offers[0].AchievementPointName}
                isSur={data.offers[0].isSur}
                basePoint={data.offers[0].basePoint}
                actualPoint={data.offers[0].actualPoint}
                dlBtnName={
                  data.offers[0].advertisementId ? mediaData.dlBtnNameApp : mediaData.dlBtnNameWeb
                }
                isOpen={popupIndex === i}
                isTripMile={mediaData.offerwallType === offerType.TRIP_MILE}
                nextpopup={nextpopup}
                bonusPoint={
                  data.offers[0].popupOfferConversionPoints[
                    data.offers[0].popupOfferConversionPoints.length - 1
                  ]?.bonusPoint
                }
              />
            ) : (
              data.type === popupType.CAMPAIGN && (
                <RecommendCampaign
                  linkUrl={data.offers[0].linkUrl}
                  color={{
                    appAreaBgColor: mediaData.colors.appAreaBgColor,
                    appAreaTxtColor: mediaData.colors.appAreaTxtColor,
                    dlBtnBgColor: mediaData.colors.dlBtnBgColor,
                    dlBtnTxtColor: mediaData.colors.dlBtnTxtColor,
                  }}
                  imageUrl={data.offers[0].popupBannerUrl}
                  name={data.offers[0].popupText}
                  dlBtnName={data.offers[0].popupButtonName}
                  startAt={data.offers[0].startAt}
                  endAt={data.offers[0].endAt}
                  isOpen={popupIndex === i}
                  isTripMile={mediaData.offerwallType === offerType.TRIP_MILE}
                  nextpopup={nextpopup}
                />
              )
            )}
          </div>
        ))}
      {isMenu &&
      (mediaData.offerwallType === offerType.LINE_MANGA ||
        mediaData.offerwallType === offerType.PICCOMA_APP ||
        mediaData.offerwallType === offerType.PICCOMA_WEB ||
        mediaData.offerwallType === offerType.PICKUP) ? (
        <Menu
          menus={mediaData.menus}
          color={{
            bgColor: mediaData?.colors?.titleBgColor,
            txtColor: mediaData?.colors?.titleTxtColor,
          }}
          isMenuOpen={isMenu}
          offerwallType={mediaData.offerwallType}
          tutorialHeaderImage={tutorialHeaderImage}
        />
      ) : (
        <>
          {!mediaData.isBannerTabHidden && !isPiccoma && !isLandscapeDisplay && BannerElement}
          {mediaData.isBannerTabHidden ? (
            <>
              {mediaData.offerwallType === offerType.MANGA ||
              mediaData.offerwallType === offerType.TRIP_MILE ? (
                <>
                  <OffersList
                    superSale={{
                      startAt: mediaData?.primarySuperSaleStartAt,
                      endAt: mediaData?.primarySuperSaleEndAt,
                      point: mediaData?.primarySuperSalePointMultiplier,
                    }}
                    superSaleSpecifiedOffers={mediaData?.superSaleSpecifiedOffers}
                    color={{
                      appAreaBgColor: mediaData.colors.appAreaBgColor,
                      expointBgColor: mediaData.colors.expointBgColor,
                      expointTxtColor: mediaData.colors.expointTxtColor,
                      mcvBgColor: mediaData.colors.mcvBgColor,
                      mcvTxtColor: mediaData.colors.mcvTxtColor,
                      appAreaTxtColor: mediaData.colors.appAreaTxtColor,
                      cvConditionColor: mediaData.colors.cvConditionColor,
                      dlBtnBgColor: mediaData.colors.dlBtnBgColor,
                      dlBtnTxtColor: mediaData.colors.dlBtnTxtColor,
                    }}
                    surTxt={mediaData.stepUpRewardText}
                    prTxt={mediaData.preRegistText}
                    pointIconUrl={mediaData.pointIconUrl}
                    recommendIconUrl={mediaData.recommendIconUrl}
                    pointUnit={mediaData.pointUnit}
                    bannerImage={mediaData.bannerImageUrl}
                    displayTabType={DisplayTabType.Regular}
                    tab={1}
                    surveyType={mediaData.surveyDisplayType}
                    offerwallType={mediaData.offerwallType}
                    offers={allOffers}
                    abTestSurveys={abTestSurveys}
                  />
                  {mediaData.offerwallType === offerType.TRIP_MILE && (
                    <MenuIcon
                      className="torima"
                      isMenu={isMenu}
                      switchMenu={switchMenu}
                      offerwallType={mediaData.offerwallType}
                    />
                  )}
                </>
              ) : (
                <PikkomaOffersList
                  superSale={{
                    startAt: mediaData?.primarySuperSaleStartAt,
                    endAt: mediaData?.primarySuperSaleEndAt,
                    point: mediaData?.primarySuperSalePointMultiplier,
                  }}
                  superSaleSpecifiedOffers={mediaData?.superSaleSpecifiedOffers}
                  color={{
                    appAreaTxtColor: mediaData.colors.appAreaTxtColor,
                    appAreaBgColor: mediaData.colors.appAreaBgColor,
                    mcvTxtColor: mediaData.colors.mcvTxtColor,
                    cvConditionColor: mediaData.colors.cvConditionColor,
                    clockTxtColor: mediaData.colors.expointTxtColor,
                    expointBgColor: mediaData.colors.expointBgColor,
                    expointTxtColor: mediaData.colors.expointTxtColor,
                    mcvBgColor: mediaData.colors.mcvBgColor,
                    dlBtnBgColor: mediaData.colors.dlBtnBgColor,
                    dlBtnTxtColor: mediaData.colors.dlBtnTxtColor,
                  }}
                  texts={{
                    preRegistText: mediaData.preRegistText,
                    stepUpRewardText: mediaData.stepUpRewardText,
                  }}
                  pointIconUrl={mediaData.pointIconUrl}
                  recommendIconUrl={mediaData.recommendIconUrl}
                  offers={allOffers}
                  abTestSurveys={abTestSurveys}
                />
              )}
              <div ref={setPaginateRef} />
              <Menu
                switchMenu={switchMenu}
                menus={mediaData.menus}
                color={{
                  bgColor: mediaData?.colors?.titleBgColor,
                  txtColor: mediaData?.colors?.titleTxtColor,
                }}
                isMenuOpen={isMenu}
                offerwallType={mediaData.offerwallType}
                tutorialHeaderImage={tutorialHeaderImage}
              />
            </>
          ) : offers.length > 0 ? (
            <>
              <div ref={setRef}>
                <MainContents
                  media={mediaData}
                  offers={offers}
                  tabs={tabs}
                  challengings={filterChallengings(challengings)}
                  surveys={surveys}
                  abTestSurveys={abTestSurveys}
                  fetchPaginate={paginate}
                  isFixedMenu={isFixedMenu}
                  PickupOffers={PickupOffers}
                  onScrollTopToTabs={onScrollTopToTabs}
                  BannerElement={BannerElement}
                  isTabsScroll={isTabsScroll}
                  isLandscape={isLandscapeDisplay}
                />
              </div>
              {(mediaData.offerwallType === offerType.MANGA ||
                mediaData.offerwallType === offerType.TRIP_MILE) && (
                <Menu
                  switchMenu={switchMenu}
                  menus={mediaData.menus}
                  color={{
                    bgColor:
                      mediaData.offerwallType === offerType.MANGA
                        ? mediaData?.colors?.titleBgColor
                        : mediaData?.colors?.appAreaBgColor,
                    txtColor: mediaData?.colors?.titleTxtColor,
                  }}
                  isMenuOpen={isMenu}
                  offerwallType={mediaData.offerwallType}
                />
              )}
              {mediaData.offerwallType === offerType.TRIP_MILE && (
                <MenuIcon
                  className="torima"
                  isMenu={isMenu}
                  switchMenu={switchMenu}
                  offerwallType={mediaData.offerwallType}
                />
              )}
            </>
          ) : (
            <>
              {!mediaData.isBannerTabHidden &&
                (isLandscapeDisplay || (!isLandscapeDisplay && isPiccoma)) &&
                BannerElement}
              <NoOfferContent
                switchMenu={switchMenu}
                mediaData={{
                  offerwallType: mediaData.offerwallType,
                  menus: mediaData.menus,
                  colors: {
                    titleBgColor: mediaData?.colors?.titleBgColor,
                    titleTxtColor: mediaData?.colors?.titleTxtColor,
                  },
                  footerText: mediaData.footerText,
                }}
                isMenu={isMenu}
              />

              {(mediaData.offerwallType === offerType.MANGA ||
                mediaData.offerwallType === offerType.TRIP_MILE) && (
                <Menu
                  switchMenu={switchMenu}
                  menus={mediaData.menus}
                  color={{
                    bgColor:
                      mediaData.offerwallType === offerType.MANGA
                        ? mediaData?.colors?.titleBgColor
                        : mediaData?.colors?.appAreaBgColor,
                    txtColor: mediaData?.colors?.titleTxtColor,
                  }}
                  isMenuOpen={isMenu}
                  offerwallType={mediaData.offerwallType}
                />
              )}
              {mediaData.offerwallType === offerType.TRIP_MILE && (
                <MenuIcon
                  className="torima"
                  isMenu={isMenu}
                  switchMenu={switchMenu}
                  offerwallType={mediaData.offerwallType}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};
